document.addEventListener('DOMContentLoaded', () => {
    const timelineItems = document.querySelectorAll('.timeline-item');
  
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const timelineIcon = entry.target.querySelector('.timeline-icon');
        if (entry.isIntersecting) {
          timelineIcon.classList.add('active');
        } else {
          timelineIcon.classList.remove('active');
        }
      });
    }, { threshold: 0.9 });
  
    timelineItems.forEach(item => {
      observer.observe(item);
    });
  });
  